import convertHextoRgb from '@/functions/convertHextoRgb'
import extractRgbValues from '@/functions/extractRgbValues'
import stripAllTags from '@/functions/stripAllTags'
import cn from 'classnames'
import dynamic from 'next/dynamic'
import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import React from 'react'
import {isMobile} from 'react-device-detect'
import customCloudinaryLoader from '@/functions/customCloudinaryLoader'
import styles from './Hero.module.css'

const Button = dynamic(() => import('@/components/atoms/Button'))

/**
 * Render the DuotoneFilter component.
 * @author Americaneagle.com
 * @param  {object}  props           Component props.
 * @param  {string}  props.className The className.
 * @param  {Array}   props.duotone   Array of duotone color values.
 * @param  {string}  props.id        Unique filter ID.
 * @return {Element}                 The DuotoneFilter component.
 */
function DuotoneFilter({className, duotone, id}) {
  const rgbValues =
    duotone?.length &&
    duotone.map((color) =>
      color.indexOf('#') !== -1
        ? convertHextoRgb(color)
        : extractRgbValues(color)
    )

  // Calculate R, G, B decimal values.
  const rValues = rgbValues.map((color) => color[0] / 255)
  const gValues = rgbValues.map((color) => color[1] / 255)
  const bValues = rgbValues.map((color) => color[2] / 255)

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <filter id={id}>
          <feColorMatrix
            type="matrix"
            values=".299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 0 0 0 1 0"
          ></feColorMatrix>
          <feComponentTransfer colorInterpolationFilters="sRGB">
            <feFuncR type="table" tableValues={rValues.join(' ')}></feFuncR>
            <feFuncG type="table" tableValues={gValues.join(' ')}></feFuncG>
            <feFuncB type="table" tableValues={bValues.join(' ')}></feFuncB>
          </feComponentTransfer>
        </filter>
      </defs>
    </svg>
  )
}

DuotoneFilter.propTypes = {
  className: PropTypes.string,
  duotone: PropTypes.array,
  id: PropTypes.string
}

/**
 * Render the Hero component.
 * @param  {object}  props                   Hero component props.
 * @param  {string}  props.align             The alignment of the hero component.
 * @param  {object}  props.background        The background object.
 * @param  {string}  props.backgroundType    The type of the background asset.
 * @param  {string}  props.body              Text for the body.
 * @param  {string}  props.className         The className.
 * @param  {any}     props.children          InnerBlocks.
 * @param  {string}  props.contentAlign      The alignment of the component content.
 * @param  {object}  props.ctaText           The cta text.
 * @param  {object}  props.ctaUrl            The cta url.
 * @param  {Array}   props.duotone           Array of duotone color values.
 * @param  {boolean} props.fixed             Whether the background is fixed (parallax).
 * @param  {object}  props.focalPoint        The focal point coordinates for the image.
 * @param  {boolean} props.fullHeight        Whether hero is full height.
 * @param  {string}  props.id                Optional element ID.
 * @param  {boolean} props.isClickable       Whether the whole cover is clickable when linked.
 * @param  {boolean} props.isDark            Whether the background is dark.
 * @param  {string}  props.linkRel           The link rel.
 * @param  {string}  props.linkTarget        The link target.
 * @param  {string}  props.linkText          The link text.
 * @param  {string}  props.linkUrl           The link URL.
 * @param  {number}  props.overlayOpacity    The overlay opacity as a float.
 * @param  {object}  props.overlayStyle      Custom overlay styles.
 * @param  {string}  props.poster            The poster image for the video background.
 * @param  {boolean} props.preload           Whether to preload the image.
 * @param  {boolean} props.repeat            Whether background is repeated.
 * @param  {boolean} props.showVideoOnMobile Whether to show video on mobile devices.
 * @param  {object}  props.style             Custom hero styles.
 * @param  {string}  props.subtitle          Text for the subtitle.
 * @param  {string}  props.title             Text for the title.
 * @return {Element}                         The Hero component.
 */
export default function Hero({
  align,
  background,
  backgroundType,
  body,
  className,
  children,
  contentAlign,
  ctaText,
  ctaUrl,
  duotone,
  fixed,
  focalPoint,
  fullHeight,
  id,
  isClickable,
  isDark,
  linkRel,
  linkTarget,
  linkText,
  linkUrl,
  overlayOpacity = 0.5,
  overlayStyle,
  poster,
  preload,
  repeat,
  showVideoOnMobile,
  style,
  subtitle,
  title
}) {
  const heroStyle = background?.url
    ? {
        // These css custom properties are used inside the css module file to set the card's background image, tint overlay, and fallback bg color.
        '--image-tint-color': `#00000000`,
        '--image-fallback-color': `#000`
      }
    : {}

  // Rename to stylelint-accepted const name.
  const overlayopacity = overlayOpacity

  const hasFilter = !!background?.url && !!duotone && !fixed && !repeat

  // Generate unique ID for filter.
  const filterKey = Math.random().toString(36).substring(2, 11)
  const filterId = `duotone-filter-${filterKey}`

  const filterStyle = {
    filter: `url(#${filterId})`
  }

  // Conditionally apply focal point.
  if (focalPoint) {
    filterStyle.objectPosition = `${focalPoint.x} ${focalPoint.y}`
  }

  return (
    <>
      {hasFilter && (
        <DuotoneFilter
          className={styles.filter}
          duotone={duotone}
          id={filterId}
        />
      )}
      <div
        id={id}
        className={cn(
          styles.hero,
          'hero',
          className,
          align === 'full' ? styles.alignFull : null,
          align === 'wide' ? styles.alignWide : null,
          align === 'left' ? styles.alignLeft : null,
          align === 'right' ? styles.alignRight : null,
          contentAlign && contentAlign.includes('top')
            ? styles.contentAlignTop
            : null,
          contentAlign && contentAlign.includes('bottom')
            ? styles.contentAlignBottom
            : null,
          contentAlign && contentAlign.includes('left')
            ? styles.contentAlignLeft
            : null,
          contentAlign && contentAlign.includes('right')
            ? styles.contentAlignRight
            : null,
          fixed ? styles.fixed : null,
          fullHeight ? styles.fullHeight : null,
          repeat ? styles.repeat : null
        )}
        style={{
          ...style,
          ...heroStyle
        }}
      >
        {isClickable && linkUrl && (
          <a
            className={cn(styles.link, 'hero__link')}
            href={linkUrl}
            target={linkTarget}
            aria-label={stripAllTags(
              `${linkText}${
                linkTarget === '_blank' ? ' (opens in a new window)' : ''
              }`
            )}
            rel={linkRel || null}
          />
        )}
        <div
          className={cn(
            styles.overlay,
            'hero__overlay',
            !background?.url ? styles.overlayOnly : null
          )}
          style={{opacity: overlayopacity, ...overlayStyle}}
        ></div>
        {background?.url && backgroundType !== 'video' && (
          <Image
            loader={customCloudinaryLoader}
            src={background.url}
            layout="fill"
            objectFit="cover"
            objectPosition={focalPoint && `${focalPoint.x} ${focalPoint.y}`}
            alt="Mecum Auctions"
            priority={preload}
          />
        )}
        {background?.url &&
          backgroundType === 'video' &&
          (!isMobile || showVideoOnMobile ? (
            <video
              autoPlay
              muted
              loop
              playsInline
              poster={poster}
              src={background.url}
              style={
                focalPoint && {
                  objectPosition: `${focalPoint.x} ${focalPoint.y}`
                }
              }
            />
          ) : (
            <Image
              loader={customCloudinaryLoader}
              src={poster}
              layout="fill"
              objectFit="cover"
              objectPosition={focalPoint && `${focalPoint.x} ${focalPoint.y}`}
              alt={document?.title || 'Mecum Auctions'}
              priority={preload}
            />
          ))}
        <div
          className={cn(
            styles.content,
            isDark ? styles.whiteText : styles.blackText,
            'hero__content'
          )}
        >
          {!!subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          {!!title && <h1 className={styles.title}>{title}</h1>}
          {!!body && <p className={styles.body}>{body}</p>}
          {!!ctaText && ctaUrl && (
            <Button
              className={styles.button}
              url={ctaUrl}
              text={ctaText}
              icon="arrowRight"
              type="primary"
              size="md"
            />
          )}
          {children && children}
        </div>

        {hasFilter && (
          <img
            alt=""
            className={styles.filterImage}
            src={background?.url}
            style={filterStyle}
          />
        )}
      </div>
    </>
  )
}

Hero.propTypes = {
  align: PropTypes.string,
  background: PropTypes.object,
  backgroundType: PropTypes.string,
  body: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  contentAlign: PropTypes.string,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  duotone: PropTypes.array,
  fixed: PropTypes.bool,
  focalPoint: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string
  }),
  fullHeight: PropTypes.bool,
  id: PropTypes.string,
  isClickable: PropTypes.bool,
  linkRel: PropTypes.string,
  linkTarget: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  overlayOpacity: PropTypes.number,
  overlayStyle: PropTypes.object,
  poster: PropTypes.string,
  preload: PropTypes.bool,
  repeat: PropTypes.bool,
  showVideoOnMobile: PropTypes.bool,
  style: PropTypes.object,
  subtitle: PropTypes.string,
  title: PropTypes.string
}
