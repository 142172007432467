import {theme} from '../tailwind.config'

/**
 * Get hex value from color names.
 * @author Americaneagle.com
 * @param  {string} colorName The color name.
 * @return {string}           The color hex value.
 */
export default function getColorsHexValue(colorName) {
  return theme.colors?.[colorName] ?? ''
}
