const colors = require('tailwindcss/colors')
const plugin = require('tailwindcss/plugin')

module.exports = {
  content: [
    './components/**/*.{js,ts,jsx,tsx,mdx}',
    './pages/**/*.{js,ts,jsx,tsx}'
  ],
  darkMode: 'media',
  theme: {
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: colors.black,
      blue: colors.blue,
      charcoal: '#202020',
      caption: '#6E6E6E',
      gray: {
        50: '#F9F9F9',
        100: '#EEEEEE',
        200: '#DDDDDD',
        300: '#CCCCCC',
        400: '#BCBDBF',
        500: '#999999',
        600: '#777777',
        650: '#555555',
        675: '#444444',
        700: '#333333',
        750: '#2d2d2d',
        800: '#222222',
        900: '#1f1f1f'
      },
      green: {
        base: '#64AE3E',
        hover: '#599B37'
      },
      'milano-red': '#BA1900',
      gold: '#B75C00',
      orange: colors.orange,
      purple: '#601AB0',
      red: {
        base: '#ED1C23',
        hover: '#C11B12'
      },
      teal: '#0AB4AE',
      white: colors.white,
      yellow: '#FEEE0B'
    },
    screens: {
      xs_max: {max: '479px'},
      xs: '480px',
      sm: '640px',
      md: '768px',
      lg_max: {max: '1023px'},
      lg: '1024px',
      xl: '1280px',
      '2xl': '1400px',
      '3xl': '1536px',
      '4xl': '1800px'
    },
    extend: {
      animation: {
        'prompt-scroll': 'peek 4s ease infinite'
      },
      backgroundImage: {
        'live-icon': 'url(/images/live-icon.webp)',
        'profile-icon': 'url(/images/profile-icon.webp)',
        'star-pin-icon': 'url(/images/star-pin-icon.webp)',
        'swipe-icon': 'url(/images/swipe-icon.webp)',
        'sye-arrow':
          'url(/images/start-your-experience/icon-heading-arrows.webp)'
      },
      boxShadow: {
        'inset-red': 'inset 0 0 0 2px #ED1C23',
        table: '0 4px 16px rgba(0, 0, 0, 0.2)'
      },
      container: {
        center: true,
        margin: 'auto',
        padding: '20px',
        screens: {
          sm: '100%',
          md: '100%',
          lg: '1024px',
          xl: '1400px'
        }
      },
      fontFamily: {
        primary: ['var(--primary-font)'],
        secondary: ['var(--secondary-font)'],
        body: ['var(--body-font)']
      },
      keyframes: {
        peek: {
          0: {
            transform: 'translate3d(0, 0, 0)'
          },
          '10%': {
            transform: 'translate3d(-50px, 0, 0)'
          },
          '20%': {
            transform: 'translate3d(0, 0, 0)'
          },
          '100%': {
            transform: 'translate3d(0, 0, 0)'
          }
        }
      },
      maxWidth: {
        60: '60%'
      },
      scale: {
        99: '.99'
      },
      screens: {
        tall: {raw: '(min-height: 800px) and (min-width: 1024px)'},
        short: {raw: '(max-height: 730px) and (min-width: 1024px)'}
      },
      skew: {
        20: '20deg',
        30: '30deg'
      }
    }
  },
  variants: {},
  plugins: [
    plugin(function ({addUtilities}) {
      addUtilities({
        '.align-full': {
          position: 'relative',
          left: '50%',
          right: '50%',
          width: '100vw',
          'margin-left': '-50vw',
          'margin-right': '-50vw'
        },
        '.align-initial': {
          position: 'static',
          left: 'auto',
          right: 'auto',
          width: 'auto',
          'margin-right': 'auto',
          'margin-left': 'auto'
        },
        '.align-stretched': {
          position: 'static',
          left: 'auto',
          right: 'auto',
          width: 'auto',
          'margin-right': '-2em',
          'margin-left': '-2em'
        },
        '.align-wide': {
          position: 'relative',
          left: '50%',
          width: '90vw',
          'margin-left': '-45vw'
        }
      })
    }),
    require('@tailwindcss/container-queries')
  ],
  future: {}
}
