import Blocks from '@/components/molecules/Blocks'
import Hero from '@/components/organisms/Hero'
import formatFocalPoint from '@/functions/formatFocalPoint'
import getColorsHexValue from '@/functions/getColorsHexValue'
import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'

/**
 * Cover Block
 *
 * The core Columns block from Gutenberg.
 * @author Americaneagle.com
 * @param  {object}  props                   The component properties.
 * @param  {string}  props.align             Optional alignment style.
 * @param  {string}  props.anchor            Optional anchor/id.
 * @param  {string}  props.backgroundType    The type of the background asset.
 * @param  {string}  props.className         Optional classnames.
 * @param  {string}  props.contentPosition   The alignment of the component content.
 * @param  {string}  props.customGradient    The custom gradient color.
 * @param  {number}  props.dimRatio          The overlay dim ratio.
 * @param  {object}  props.focalPoint        The focal point coordinates for the image.
 * @param  {string}  props.gradientHex       The background gradient hex value.
 * @param  {boolean} props.hasParallax       Whether image has parallex.
 * @param  {Array}   props.innerBlocks       The array of inner blocks to display.
 * @param  {boolean} props.isClickable       Whether the whole cover is clickable when linked.
 * @param  {boolean} props.isDark            Whether the background is dark.
 * @param  {boolean} props.isRepeated        Whether image is repeated.
 * @param  {string}  props.linkRel           The link rel.
 * @param  {string}  props.linkTarget        The link target.
 * @param  {string}  props.linkText          The link text.
 * @param  {string}  props.linkUrl           The link URL.
 * @param  {number}  props.minHeight         The image minimum height.
 * @param  {string}  props.minHeightUnit     The image minimum height unit.
 * @param  {string}  props.overlayColor      The overlay color name value.
 * @param  {string}  props.overlayColorHex   The overlay color hex value.
 * @param  {string}  props.poster            The poster image for the video background.
 * @param  {boolean} props.preload           Whether to preload the image.
 * @param  {boolean} props.showVideoOnMobile Whether to show video on mobile devices.
 * @param  {object}  props.style             The style attributes.
 * @param  {string}  props.url               The image src attribute.
 * @return {Element}                         The Cover component.
 */
export default function BlockCover({
  align,
  anchor,
  backgroundType,
  className,
  contentPosition,
  customGradient,
  dimRatio,
  focalPoint,
  gradientHex,
  hasParallax,
  innerBlocks,
  isClickable,
  isDark,
  isRepeated,
  linkRel,
  linkTarget,
  linkText,
  linkUrl,
  minHeight,
  minHeightUnit = 'px',
  overlayColor: overlayColorName,
  overlayColorHex,
  poster,
  preload = false,
  showVideoOnMobile = false,
  style,
  url
}) {
  const overlayColor =
    getColorsHexValue(overlayColorName) || overlayColorHex || '#000000'
  const overlayGradient = customGradient || gradientHex
  const overlayOpacity = url ? dimRatio / 100 : 1
  const fullHeight = minHeight === 100 && minHeightUnit === 'vh'

  // Add custom styles for Hero.
  const coverStyle = {}
  const overlayStyle = {}

  if (overlayColor && !overlayGradient) {
    overlayStyle.backgroundColor = overlayColor
  }

  if (overlayGradient) {
    overlayStyle.background = overlayGradient
  }

  if (minHeight && !fullHeight) {
    coverStyle.minHeight = `${minHeight}${minHeightUnit}`
  }

  const newFocalPoint = formatFocalPoint(focalPoint)

  const [newInnerBlocks, setInnerBlocks] = useState()

  // Add extra class(es) to inner blocks on initial load.
  useEffect(() => {
    setInnerBlocks(
      !innerBlocks?.length
        ? []
        : innerBlocks.map((block) => {
            const classes = (block?.attributes?.className ?? '').split(' ')

            // Extra check to only add class once.
            if (classes.includes('relative')) {
              return block
            }

            block.attributes = {
              ...block?.attributes,
              className: `${block?.attributes?.className || ''} relative z-10`
            }

            return block
          })
    )
  }, [innerBlocks])

  // Only proceed if we're provided a media URL or a user-selected overlay color/gradient.
  if (!url && !overlayColor && !overlayGradient) {
    return null
  }

  return (
    <Hero
      align={align}
      background={{url}}
      backgroundType={backgroundType}
      className={className}
      contentAlign={contentPosition}
      duotone={style?.color?.duotone}
      fixed={hasParallax}
      focalPoint={newFocalPoint}
      fullHeight={fullHeight}
      id={anchor}
      isClickable={isClickable}
      isDark={isDark}
      linkRel={linkRel}
      linkTarget={linkTarget}
      linkText={linkText}
      linkUrl={linkUrl}
      overlayOpacity={overlayOpacity}
      poster={poster}
      preload={preload}
      repeat={isRepeated}
      overlayStyle={overlayStyle}
      showVideoOnMobile={showVideoOnMobile}
      style={coverStyle}
    >
      {!!newInnerBlocks?.length && <Blocks blocks={newInnerBlocks} />}
    </Hero>
  )
}

BlockCover.propTypes = {
  align: PropTypes.string,
  anchor: PropTypes.string,
  backgroundType: PropTypes.string,
  className: PropTypes.string,
  contentPosition: PropTypes.string,
  customGradient: PropTypes.string,
  dimRatio: PropTypes.number,
  focalPoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  gradientHex: PropTypes.string,
  hasParallax: PropTypes.bool,
  innerBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      attributes: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    })
  ),
  isClickable: PropTypes.bool,
  isDark: PropTypes.bool,
  isRepeated: PropTypes.bool,
  linkRel: PropTypes.string,
  linkTarget: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  minHeight: PropTypes.number,
  minHeightUnit: PropTypes.string,
  overlayColor: PropTypes.string,
  overlayColorHex: PropTypes.string,
  poster: PropTypes.string,
  preload: PropTypes.bool,
  showVideoOnMobile: PropTypes.bool,
  style: PropTypes.object,
  url: PropTypes.string
}
